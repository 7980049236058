import React from "react"

import { useStaticQuery, graphql } from "gatsby"

const Logo = (props) => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "LogoMultimarca_LD.png" }) {
        childImageSharp {
          fluid(maxWidth: 258, maxHeight: 20) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.placeholderImage?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return <img alt="PACIFIKA"
  style={{
      maxWidth: '258px',
      maxHeight: '20px'
  }}
  src={data.placeholderImage.childImageSharp.fluid.src} />
}

export default Logo