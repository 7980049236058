import React from "react";

import PropTypes from "prop-types";
import Logo from "./logo";
import { Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";

import "./header.css"
import { gql, useQuery } from "@apollo/client";

const REFERENCIAS_QUERY = gql`
query ObtenerValoresReferencia($tipo: String!){
    referencias(tipo: $tipo){
        nombre,
        valor
    }
}
`;

const Header = (props) => {

  const { data: linksBeneficios, loading: loadingBeneficios } = useQuery(REFERENCIAS_QUERY, {
    variables: {
      tipo: 'LINK_BENEFICIOS'
    }
  })
  const { data: linksCatalogos } = useQuery(REFERENCIAS_QUERY, {
    variables: {
      tipo: 'LINK_CATALOGO'
    }
  })

  const { data: linksAsesora } = useQuery(REFERENCIAS_QUERY, {
    variables: {
      tipo: 'LINK_ASESORA'
    }
  })

  const { data: linksPedidos } = useQuery(REFERENCIAS_QUERY, {
    variables: {
      tipo: 'LINK_PEDIDOS'
    }
  })


  if (loadingBeneficios) {

    return <div>Cargando</div>;
  }

  return (
    <header
      className={props.className}
      style={{
        background: `#ffffff`,
        marginBottom: `1.45rem`,
        boxShadow: '0px 4px 30px -4px rgb(0 51 90 / 10%)',
        zIndex: 99,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1190,
          padding: `1.45rem 1.0875rem`,
        }}
      >

        <Navbar collapseOnSelect expand="lg" bg="transparent">
          <Navbar.Brand>
            <Logo
              to="/"
              style={{
                maxHeight: '18px',
                maxWidth: '176px'
              }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <NavDropdown className="dark pr-5" title="Beneficios" id="basic-nav-dropdown">
                {linksBeneficios ? linksBeneficios.referencias.map((link) => {
                  return <NavDropdown.Item href={link.valor}>{link.nombre}</NavDropdown.Item>;
                }) : <></>}
              </NavDropdown>
              <NavDropdown className="dark pr-5" title="¿Qué es ser asesora?" id="basic-nav-dropdown">
                {linksAsesora ? linksAsesora.referencias.map((link) => {
                  return <NavDropdown.Item href={link.valor}>{link.nombre}</NavDropdown.Item>;
                }) : <></>}
              </NavDropdown>
              <NavDropdown className="dark pr-5" title="Ver catálogo" id="basic-nav-dropdown">
                {linksCatalogos ? linksCatalogos.referencias.map((link) => {
                  return <NavDropdown.Item href={link.valor}>{link.nombre}</NavDropdown.Item>;
                }) : <></>}
              </NavDropdown>
            </Nav>
            {/* <Button variant="outline-warning" href="https://pedidos.pacifika.com.pe/" className="btn-pedidos" >Realizar Pedido</Button> */}
            <Dropdown className="dark pr-5">
              <Dropdown.Toggle variant="outline-warning" className="btn-pedidos" id="dropdown-basic">
                Realizar Pedido
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {linksPedidos ? linksPedidos.referencias.map((link) => {
                  return <Dropdown.Item href={link.valor}>{link.nombre}</Dropdown.Item>;
                }) : <></>}
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
};




export default Header
