/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../header/header"
import "./layout.css"
// import Footer from "../footer/footer"
import { Row } from "react-bootstrap"


const Layout = ({ children , customerSupporLink }) => {

const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      placeholderImage: file(relativePath: { eq: "Icon_WhatsApp.png" }) {
        childImageSharp {
          fluid(maxWidth: 42, maxHeight: 42) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <a className="icon_whatsApp" id="whatsappChatButton" target="_blank" rel="noreferrer" style={{bottom: '50%'}}
        href={customerSupporLink}>
                <img alt="chat" height={45} src={data.placeholderImage.childImageSharp.fluid.src} />
                <span className="text-dark">Chat</span>
            </a>
      <Header className="sticky-nav" siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div className="mainContainer" >
        <div className="grid-wrapper" >{children}</div>
        <Row className="d-flex status-search">
          <div className="ml-auto mr-auto" >
            <strong>¿Ya te inscribiste?</strong> &nbsp; Consulta tu estado de inscripcion <a className="c-brand-color" href={customerSupporLink}>&nbsp;<ins>aquí</ins></a>
          </div>
        </Row>

        {/* <Footer>
        </Footer> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
